import React from 'react';
import Logo from '@assets/silverlife.png';
import Segment from '../../components/segment';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import HeroCTA from '../../components/sections/heroCTA/heroCTA';
import SocialProofQuoteCardST from '../../components/staticSections/SocialProofQuoteCardST/SocialProofQuoteCardST';
import GetStartedST from '../../components/staticSections/GetStartedST/GetStartedST';
import BgCardsTextST from '../../components/staticSections/bgCardsTextST/BgCardsTextST';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import LicensedPtSliderST from '../../components/staticSections/LicensedPtSliderST/LicensedPtSliderST';
import Testimonial from '../../components/sections/testimonial/testimonial';
import OutcomeST from '../../components/staticSections/OutcomeST/OutcomeST';
import Treatments from '../../components/staticSections/Treatments/Treatments';

const IndexPage = () => {
  const lang = 'se_sv';
  return (
    <Layout
      backgroundColor="#172D47!important"
      textColor="#06E55C!important"
      headerWitdh="header"
      headerStyle="flex--space-between"
      showFooter
      headerImage={
        <img className="logo--md margin-left--xs flex" alt="Silverlife" src={Logo} />
      }
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_SE} />
      <Seo title="Silverlife" language="sv" />
      <HeroCTA
        dataTheme="ice-blue"
        imagedataNoCerts
        dataSection="hero_CTA"
        alt="blue shield of california"
        title="Behandla artros och ledsmärta direkt i mobilen"
        titleClassName="bold"
        text="Med Joint Academy får du ett skräddarsytt träningsprogram för att minska din ledsmärta. Patientavgift framgår i appen och ingår i högkostnadsskyddet, frikort gäller."
        tagHeadline="Få ett sms med länk för att ladda ner appen"
        smsTag
        dataToken="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOiIyMDIxLTAyLTIyVDA5OjM5OjAwLjk4OVoiLCJqdGkiOiI1NjAxYWVmZi1mZTY5LTQyYWItYTgwYy0zNjhlNzU5NjNhZjMiLCJhZmZpbGlhdGVfbmFtZSI6IlNpbHZlcmxpZmUtZ2VuZXJlbCBsw6RuayJ9.E1GgegYsLz7J8BVfXiqjMuDCUoqr0KSwErJyUbaj9wQ"
      />
      <SocialProofQuoteCardST
        lang={lang}
        dataTheme="white"
        extraMargin="margin-top--lg"
      />
      <GetStartedST
        lang={lang}
        renderDownloadButton
        ctaButtonText="kom igång idag"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="#herotext"
      />
      <BgCardsTextST dataTheme="light" lang={lang} />
      <Treatments lang={lang} downloadhref="#herotext" />
      <SocialProofFeedST lang={lang} />
      <Testimonial
        dataTheme="white"
        renderTitle
        titleClassName=""
        videoSrcURL="https://player.vimeo.com/video/332237175?color=fff&title=0&byline=0&portrait=0"
        videoTitle="“I can’t imagine a day without Joint Academy”"
      />
      <LicensedPtSliderST
        lang={lang}
        downloaddataGoal="Onboarding Viewed"
        downloadhref="#herotext"
        downloadclassname="btn btn--fullwidth btn--md btn--primary btn--lgMob"
        downloadtext="Kom igång idag"
      />
      <OutcomeST lang={lang} />
    </Layout>
  );
};

export default IndexPage;
